<template>
  <b-container fluid>
    <b-row>
      <b-col class="mb-4" cols="12">
        <b-button-toolbar class="float-right me-4">
          <b-button-group>
            <b-form-checkbox
              id="checkbox-sgh"
              v-model="showGroupedHistory"
              name="checkbox-grouped"
              class="me-1 align-middle mt-3"
            >
              <span class="ms-2">Histórico</span>
            </b-form-checkbox>

            <b-input-group>
              <b-button
                class="ms-2"
                variant="outline-info"
                @click="updateQuotes"
                >Atualizar Cotações</b-button
              >
              <b-form-datepicker v-model="dt_quote"></b-form-datepicker>
            </b-input-group>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
      <grouped-history
        v-if="showGroupedHistory"
        class="mt-2 mb-4"
      ></grouped-history>

      <hr />
      <b-col cols="12" class="mt-4 mb-1">
        <b-input-group class="mb-1" size="md">
          <b-form-checkbox
            id="checkbox-1"
            v-model="isGrouped"
            name="checkbox-grouped"
            class="mr-1 align-middle mt-1"
            @change="getInvestments()"
            switch
          >
            <span class="ml-1 mb-2">Visão Agrupada</span>
          </b-form-checkbox>

          <b-form-group class="ml-1 align-middle mt-1" v-if="!isGrouped">
            <b-form-radio-group
              v-model="selectedGrouping"
              :options="optionsGrouping"
              name="radio-inline"
              @change="getInvestments"
            ></b-form-radio-group>
          </b-form-group>
        </b-input-group>
      </b-col>
      <b-col class="mb-2">
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            class="ml-1"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-card title="Saldo Atual">
          <b-table
            :items="items"
            :fields="fields"
            :show-empty="showEmpty"
            :filter="filter"
            @filtered="onFiltered"
            hover
            :sort-desc="sortDesc"
            :perPage="perPage"
            :current-page="currentPage"
          >
            <!-- A custom formatted column -->
            <template #cell(is_liquid)="data">
              <b-icon-check v-if="data.item.is_liquid"></b-icon-check>
              <b-icon-x v-if="!data.item.is_liquid"></b-icon-x>
            </template>

            <template #cell(valvar)="data">
              <span
                v-bind:class="{
                  'text-success':
                    parseFloat(data.item.valvar.replace(',', '.')) > 0.0,
                  'text-danger':
                    parseFloat(data.item.valvar.replace(',', '.')) < 0.0,
                }"
              >
                {{ data.item.valvar }}</span
              >
            </template>

            <template #cell(perc_var)="data">
              <span
                v-bind:class="{
                  'text-success':
                    parseFloat(
                      data.item.perc_var.toString().replace(',', '.')
                    ) > 0.0,
                  'text-danger':
                    parseFloat(data.item.perc_var.replace(',', '.')) < 0.0,
                }"
              >
                {{ data.item.perc_var }}</span
              >
            </template>

            <template #cell(actions)="row">
              <b-icon-plus-square
                v-if="!hasAvgPrice(row.item.nu_type)"
                @click="modalEntry(row)"
              ></b-icon-plus-square>
              <b-icon-clock-history
                class="ml-2"
                @click="showHistory(row)"
              ></b-icon-clock-history>

              <b-icon-journal-minus class="ml-2" @click="showExpenseModal(row)">
              </b-icon-journal-minus>

              <b-icon-calculator
                class="ml-2"
                v-if="hasAvgPrice(row.item.nu_type)"
                @click="modalAvgQuoteHistory(row)"
              >
              </b-icon-calculator>

              <b-icon-arrow-clockwise
                class="ml-2"
                @click="repeatBalance(row)"
                v-if="!hasAvgPrice(row.item.nu_type)"
              >
              </b-icon-arrow-clockwise>
            </template>

            <template slot="bottom-row">
              <td v-for="field in summaryData" :key="field.key">
                <variation
                  :key="field.key"
                  :valor="field.toString()"
                ></variation>
              </td>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          >
          </b-pagination>
        </b-card>

        <entry-modal
          :row="row"
          :key="row.id"
          id="new_entry"
          v-if="showEntryModal"
        ></entry-modal>

        <entry-dividend
          :row="row"
          id="modal_dividend"
          v-if="showModalDividend"
        ></entry-dividend>

        <entry-expense
          :row="row"
          id="modal_dividend"
          v-if="showModalExpense"
        ></entry-expense>

        <history-modal
          :row="row"
          :key="'h' + row.item.id + '_' + Date.now()"
          :id_modal="id_modal_history"
          v-if="showHistoryModal"
        ></history-modal>

        <history-quote-modal
          :row="row"
          :key="'h' + row.item.id + '_' + Date.now()"
          :id_modal="id_modal_quote_history"
          v-if="showHistoryQuoteModal"
        ></history-quote-modal>

        <history-avg-quote-modal
          :row="row"
          :key="'h' + row.item.id + '_' + Date.now()"
          id_modal="id_modal_avgquote_history"
          v-if="showHistoryAvgQuoteModal"
        ></history-avg-quote-modal>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mt-4 mb-4">
      <b-col cols="12">
        <chart-container
          v-if="items != null"
          :groupedData="items"
          :key="items.length"
          :isgrouped="isGrouped"
        ></chart-container>
      </b-col>
    </b-row>

    <hr />

    <hr />
  </b-container>
</template>

<script>
import Variation from "../components/Variation.vue";
const vcr = require("./../helpers/varculator");
const moment = require("moment");

export default {
  components: { Variation },

  data() {
    return {
      currentPage: 1,
      perPage: 10,
      dt_quote: new Date(),
      filter: "",
      id_modal_history: "modal_history",
      id_modal_quote_history: "modal_quote_history",
      items: null,
      items_grouped: null,
      items_individual: null,
      items_stocks: null,
      items_acoes: null,
      showEmpty: true,
      sortDesc: true,
      totalRows: 0,
      selectedGrouping: "gall",
      optionsGrouping: [
        { text: "All", value: "gall" },
        { text: "Stocks", value: "gstocks" },
        { text: "Ações", value: "gacoes" },
      ],
      fields_grouped: [
        {
          key: "no_type",
          sum: false,
          label: "Tipo",
          sortable: true,
        },
        {
          key: "no_currency",
          sum: false,
          label: "Moeda",
          sortable: true,
        },

        {
          key: "nu_invested_brl",
          sum: false,
          label: "Aplicado R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },

        {
          key: "nu_invested",
          sum: false,
          label: "Total Aplicado R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "current_balance_brl",
          sum: true,
          label: "Saldo R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "nu_current_balance",
          sum: true,
          label: "Saldo Total R$",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
        {
          key: "valvar",
          sum: false,
          label: "Variação",
          sortable: true,
          formatter: (value) => {
            return parseFloat(vcr.localeNumToFloat(value)).toFixed(2);
          },
        },
        {
          key: "perc_var",
          sum: false,
          label: "% Var. Total",
          sortable: true,
          // formatter: (value) => {
          //   return parseFloat(value).toFixed(2);
          // }
        },
        {
          key: "current_balance_usd",
          sum: true,
          label: "Aplicado $",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
          variant: "info",
        },
        {
          key: "currency_price",
          sum: false,
          label: "Cotação",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
          variant: "info",
        },
      ],
      fields_detailed: [
        {
          key: "nu_name",
          sum: false,
          sortable: true,
        },
        {
          key: "no_type",
          sum: false,
          label: "Tipo",
          sortable: true,
        },
        {
          key: "dt_appl",
          sum: false,
          label: "Data Aplicação",
          formatter: "yyyymmddToddmmyyyy",
          sortable: true,
        },
        { key: "shares", sum: false, label: "Shares", sortable: true },
        {
          key: "nu_invested",
          sum: false,
          label: "Valor Aplicado",
          sortable: true,
        },
        {
          key: "max_date",
          sum: false,
          label: "Posição",
          sortable: true,
          formatter: "yyyymmddToddmmyyyy",
        },

        {
          key: "nu_current_balance",
          sum: false,
          label: "Saldo Atual",
          sortable: true,
        },

        {
          key: "valvar",
          sum: true,
          label: "Variação",
          sortable: true,
          sortByFormatted: true,
          formatter: (value) => {
            return +value.replace(",", ".");
          },
        },
        {
          key: "perc_var",
          sum: false,
          label: "% Var. Total",
          sortable: true,
          sortByFormatted: true,
          formatter: (value) => {
            return +value.replace(",", ".");
          },
        },

        { key: "actions", label: "" },
        {
          key: "is_liquid",
          sum: false,
          label: "Líquido?",
          sortable: true,
        },
        {
          key: "dt_expiration",
          sum: false,
          label: "Data Resgate",
          formatter: "yyyymmddToddmmyyyy",
          sortable: true,
        },
      ],
      fields_stocks: [
        {
          key: "nu_name",
          sum: false,
          sortable: true,
        },
        {
          key: "no_type",
          sum: false,
          label: "Tipo",
          sortable: true,
        },
        { key: "shares", sum: false, label: "Shares", sortable: true },
        {
          key: "average_price",
          sum: false,
          label: "Average Price",
          sortable: true,
          formatter: (value) => {
            return value.toFixed(2);
          },
        },
        {
          key: "current_price",
          sum: false,
          label: "Share Price",
          sortable: true,
        },
        {
          key: "nu_invested",
          sum: false,
          label: "Valor Aplicado",
          sortable: true,
        },
        {
          key: "max_date",
          sum: false,
          label: "Posição",
          sortable: true,
          formatter: "yyyymmddToddmmyyyy",
        },

        {
          key: "nu_current_balance",
          sum: false,
          label: "Saldo Atual",
          sortable: true,
        },

        {
          key: "valvar",
          sum: true,
          label: "Variação",
          sortable: true,
          sortByFormatted: true,
          formatter: (value) => {
            return +value.replace(",", ".");
          },
        },
        {
          key: "perc_var",
          sum: false,
          label: "% Var. Total",
          sortable: true,
          sortByFormatted: true,
          formatter: (value) => {
            return +value.replace(",", ".");
          },
        },
      ],
      isGrouped: false,
      allBRL: false,
      showGroupedHistory: true,
      actions: [{}],
      row: {},
      showEntryModal: false,
      showHistoryModal: false,
      showHistoryAvgQuoteModal: false,
      showHistoryQuoteModal: false,
      showEntryVarModal: false,
      showModalDividend: false,
      showModalExpense: false,
    };
  },
  mounted() {
    this.getInvestments();
  },
  methods: {
    hasAvgPrice(ntype) {
      return ntype == 5 || ntype == 6;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //update locally repeated values
    updateLocalBalance(item) {
      let temp = this.items.find((el) => (el.id = item.id));

      temp.max_date = item.max_date;
      temp.nu_current_balance = item.nu_current_balance;
    },
  
    repeatBalance(row) {
      this.axios.post("/api/balance", { id: row.item.id }).then((response) => {
        if (response.data.msg) {
          this.$bvToast.toast(response.data.msg, {
            title: "Info",
            //autoHideDelay: 5000  ,
            variant: "success",
            appendToast: false,
          });

          row.item.max_date = moment(new Date()).format("YYYY-MM-DD");

          this.updateLocalBalance(row.item);

          return;
        }

        if (response.data.err) {
          this.$bvToast.toast(response.data.err, {
            title: "Error",
            //autoHideDelay: 5000   ,
            variant: "danger",
            appendToast: false,
          });
        }
      });
    },
    updateQuotes() {
      this.axios.post(
        "/api/quotes/" + moment(this.dt_quote).format("YYYY-MM-DD")
      );
    },
    async fetchdados(urlroute) {
      return (await this.axios.get(urlroute)).data;
    },
    async getInvestments() {
      if (this.isGrouped) {
        this.selectedGrouping = null;

        if (this.items_grouped == null) {
          let urlroute = "/api/grouped-balance";
          this.items_grouped = await this.fetchdados(urlroute);
          await this.computeVariations("items_grouped");
        }

        this.items = this.items_grouped;
      }

      if (!this.isGrouped) {
        if (this.items_individual == null) {
          let urlroute = "/api/balance";
          this.items_individual = await this.fetchdados(urlroute);
          await this.computeVariations("items_individual");
        }

        if (this.selectedGrouping == "gstocks") {
          if (this.items_stocks == null) {
            this.items_stocks = await this.computeStocksSameTicker();
            await this.computeVariations("items_stocks");
          }

          this.items = this.items_stocks;
          return;
        }

        if (this.selectedGrouping == "gacoes") {
          if (this.items_acoes == null) {
            this.items_acoes = await this.computeAcoesSameTicker();
            await this.computeVariations("items_acoes");
          }

          this.items = this.items_acoes;
          return;
        }

        if (this.selectedGrouping == "gall") {
          this.items = this.items_individual;
        }
      }
    },
    computeVariations(array_name) {
      this[array_name].forEach((el) => {
        this.$set(
          el,
          "valvar",
          (el.nu_current_balance - el.nu_invested).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })
        );

        this.$set(
          el,
          "perc_var",
          vcr.calcPercVar(el.valvar, el.nu_invested).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          })
        );
      });
    },
    computeAcoesSameTicker() {
      return this.computeStocksGroupedTicker(5);
    },
    computeStocksSameTicker() {
      return this.computeStocksGroupedTicker(6);
    },
    computeStocksGroupedTicker(no_type) {
      let stocksdata = this.items_individual.filter(
        (el) => el.nu_type == no_type
      );

      //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
      let unique = (a, t = {}) => a.filter((e) => !(t[e] = e in t));

      let tickernames = unique(stocksdata.map((el) => el.nu_name));

      let grouped_ticker = [];

      tickernames.forEach((el) => {
        grouped_ticker.push(stocksdata.filter((el2) => el2.nu_name == el));
      });

      let final_data = [];

      grouped_ticker.forEach((el) => {
        let temp = {};
        temp.nu_name = el[0].nu_name;
        temp.currency_price = el[0].currency_price;
        temp.dt_appl = null;
        temp.dt_expiration = null;
        temp.dt_withdraw = null;
        temp.vl_withdraw = null;
        temp.id = el[0].nu_name;
        temp.investment_category = el[0].investment_category;
        temp.is_liquid = el[0].is_liquid;
        temp.max_date = el[0].max_date;
        temp.no_currency = el[0].no_currency;
        temp.no_type = el[0].no_type;
        temp.current_price = el[0].current_price;
        temp.nu_current_balance = el
          .reduce(
            (ctotal, amount) => ctotal + parseFloat(amount.nu_current_balance),
            0
          )
          .toFixed(2);

        temp.nu_invested = el
          .reduce(
            (ctotal, amount) => ctotal + parseFloat(amount.nu_invested),
            0
          )
          .toFixed(2);

        temp.shares = el
          .reduce((ctotal, amount) => ctotal + parseFloat(amount.shares), 0)
          .toFixed(2);

        temp.average_price = temp.nu_invested / temp.shares;

        temp.perc_var = 0;
        temp.valvar = 0;
        final_data.push(temp);
      });

      return final_data;
    },
    yyyymmddToddmmyyyy(dt) {
      const dth = require("./../helpers/data_handler");
      return dth.yyyymmddToddmmyyyy(dt);
    },
    modalEntry(row) {
      this.resetModals();
      this.row = row;
      let iq = this.isQuote(row.item.nu_type);

      this.showEntryModal = !iq;
      this.showEntryVarModal = iq;

      this.$nextTick(function () {
        if (!iq) {
          this.$bvModal.show("modal-1");
          return;
        }

        if (iq) {
          this.$bvModal.show("modal-var-entry");
          return;
        }
      });
    },
    showHistory(row) {
      let iq = this.isQuote(row.item.nu_type);

      if (iq) {
        this.modalQuoteHistory(row);
        return;
      }

      if (!iq) {
        this.modalHistory(row);
        return;
      }
    },
    modalQuoteHistory(row) {
      this.resetModals();
      this.row = row;
      this.showHistoryQuoteModal = true;

      this.$nextTick(function () {
        this.$bvModal.show(this.id_modal_quote_history);
      });
    },
    modalAvgQuoteHistory(row) {
      this.resetModals();
      this.row = row;
      this.showHistoryAvgQuoteModal = true;

      this.$nextTick(function () {
        this.$bvModal.show("id_modal_avgquote_history");
      });
    },
    modalHistory(row) {
      this.resetModals();
      this.row = row;
      this.showHistoryModal = true;
      this.$nextTick(function () {
        this.$bvModal.show(this.id_modal_history);
      });
    },

    showExpenseModal(row) {
      this.row = row;
      this.resetModals();
      this.showModalExpense = true;

      this.$nextTick(function () {
        this.$bvModal.show("modal_expense");
      });
    },
    resetModals() {
      this.showEntryModal = false;
      this.showHistoryModal = false;
      this.showEntryVarModal = false;
      this.showHistoryAvgQuoteModal = false;
      this.showHistoryQuoteModal = false;
      this.showModalDividend = false;
      this.showModalExpense = false;
    },
    isQuote(type) {
      return type == 5 || type == 6;
    },
    sortMoney() {},
  },
  computed: {
    fields() {
      if (this.isGrouped) {
        return this.fields_grouped;
      }

      if (!this.isGrouped) {
        if (
          this.selectedGrouping == "gstocks" ||
          this.selectedGrouping == "gacoes"
        )
          return this.fields_stocks;

        return this.fields_detailed;
      }

      return this.fields_detailed;
    },
    summaryData() {
      let dt = [];

      if (this.items == undefined) {
        return dt;
      }

      this.fields.forEach((element) => {
        if (element.sum) {
          let dm = this.items.map((el) => el[element.key]);

          let sum = dm.reduce(function (acc, vlrA) {
            return parseFloat(acc) + parseFloat(vcr.localeNumToFloat(vlrA));
          });

          dt.push(sum.toFixed(2));
        } else {
          let pl = "";
          if (element.sumPlaceholder) {
            pl = element.sumPlaceholder;
          }
          dt.push(pl);
        }
      });

      return dt;
    },
    // rows() {
    //   return this.items.length;
    // },
  },
};
</script>

<style></style>
